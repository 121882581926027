export const WEEKLY_PROBLEMS = {
  "2024-02-11": "pell-numbers",
  "2024-02-18": "paths",
  "2024-02-25": "number-challenge-1",
  "2024-03-03": "making-squares",
  "2024-03-10": "logic-puzzles-1",
  "2024-03-17": "iq-challenge-1",
  "2024-03-24": "find-the-path",
  "2024-03-31": "counting-squares",
  "2024-04-07": "buffons-needle",
  "2024-04-14": "balls-and-books",
  "2024-04-21": "addition-squares",
  "2024-04-28": "adding-to-15",
  "2024-05-05": "9-dots",
  "2024-05-12": "treasure-hunt",
  "2024-05-19": "russian-multiplication",
  "2024-05-26": "river-crossing",
  "2024-06-02": "poisoned-wine-puzzle",
  "2024-06-09": "matchstick-puzzles",
  "2024-06-16": "magic-cards",
  "2024-06-23": "langfords-problem",
  "2024-06-30": "handshake-puzzles",
  "2024-07-07": "geometry-puzzle-1",
  "2024-07-14": "gabriels-problem",
  "2024-07-21": "fence-around-a-field",
  "2024-07-28": "domino-tilings",
  "2024-08-04": "collatz-conjecture",
  "2024-08-11": "coin-game",
  "2024-08-18": "coconut-trader",
  "2024-08-25": "circumference",
  "2024-09-01": "apple-teaser",
  "2024-09-08": "two-eggs",
  "2024-09-15": "special-numbers",
  "2024-09-22": "scales-and-weights",
  "2024-09-29": "picture-puzzles-1",
  "2024-10-06": "monkey-business",
  "2024-10-13": "locks-and-keys",
  "2024-10-20": "frog-party",
  "2024-10-27": "counting-chickens",
  "2024-11-03": "tic-tac-toe",
  "2024-11-10": "tic-tac-toe-with-levels",
  "2024-11-17": "tangrams",
  "2024-11-24": "secret-santa",
  "2024-12-01": "patience",
  "2024-12-08": "paper-pieces",
  "2024-12-15": "nim-related-games",
  "2024-12-22": "nim-21",
  "2024-12-29": "mastermind",
  "2025-01-05": "make-many",
  "2025-01-12": "letter-matching",
  "2025-01-19": "countdown",
  "2025-01-26": "15-game",
  "2025-02-02": "tetris-shapes",
  "2025-02-09": "konigsberg-bridges",
  "2025-02-16": "kaprekars-number",
  "2025-02-23": "diffy-squares",
  "2025-03-02": "where-on-earth",
  "2025-03-09": "sdt-riddles",
  "2025-03-16": "minimax",
  "2025-03-23": "colouring-maps",
  "2025-03-30": "bulgarian-solitaire",
  "2025-04-06": "pell-numbers",
  "2025-04-13": "paths",
  "2025-04-20": "number-challenge-1",
  "2025-04-27": "making-squares",
  "2025-05-04": "logic-puzzles-1",
  "2025-05-11": "iq-challenge-1",
  "2025-05-18": "find-the-path",
  "2025-05-25": "counting-squares",
  "2025-06-01": "buffons-needle",
  "2025-06-08": "balls-and-books",
  "2025-06-15": "addition-squares",
  "2025-06-22": "adding-to-15",
  "2025-06-29": "9-dots",
  "2025-07-06": "treasure-hunt",
  "2025-07-13": "russian-multiplication",
  "2025-07-20": "river-crossing",
  "2025-07-27": "poisoned-wine-puzzle",
  "2025-08-03": "matchstick-puzzles",
  "2025-08-10": "magic-cards",
  "2025-08-17": "langfords-problem",
  "2025-08-24": "handshake-puzzles",
  "2025-08-31": "geometry-puzzle-1",
  "2025-09-07": "gabriels-problem",
  "2025-09-14": "fence-around-a-field",
  "2025-09-21": "domino-tilings",
  "2025-09-28": "collatz-conjecture",
  "2025-10-05": "coin-game",
  "2025-10-12": "coconut-trader",
  "2025-10-19": "circumference",
  "2025-10-26": "apple-teaser",
  "2025-11-02": "two-eggs",
  "2025-11-09": "special-numbers",
  "2025-11-16": "scales-and-weights",
  "2025-11-23": "picture-puzzles-1",
  "2025-11-30": "monkey-business",
  "2025-12-07": "locks-and-keys",
  "2025-12-14": "frog-party",
  "2025-12-21": "counting-chickens",
  "2025-12-28": "tic-tac-toe",
  "2026-01-04": "tic-tac-toe-with-levels",
  "2026-01-11": "tangrams",
  "2026-01-18": "secret-santa",
  "2026-01-25": "patience",
  "2026-02-01": "paper-pieces",
  "2026-02-08": "nim-related-games",
  "2026-02-15": "nim-21",
  "2026-02-22": "mastermind",
  "2026-03-01": "make-many",
  "2026-03-08": "letter-matching",
  "2026-03-15": "countdown",
  "2026-03-22": "15-game",
  "2026-03-29": "tetris-shapes",
  "2026-04-05": "konigsberg-bridges",
  "2026-04-12": "kaprekars-number",
  "2026-04-19": "diffy-squares",
  "2026-04-26": "where-on-earth",
  "2026-05-03": "sdt-riddles",
  "2026-05-10": "minimax",
  "2026-05-17": "colouring-maps",
  "2026-05-24": "bulgarian-solitaire",
  "2026-05-31": "pell-numbers",
  "2026-06-07": "paths",
  "2026-06-14": "number-challenge-1",
  "2026-06-21": "making-squares",
  "2026-06-28": "logic-puzzles-1",
  "2026-07-05": "iq-challenge-1",
  "2026-07-12": "find-the-path",
  "2026-07-19": "counting-squares",
  "2026-07-26": "buffons-needle",
  "2026-08-02": "balls-and-books",
  "2026-08-09": "addition-squares",
  "2026-08-16": "adding-to-15",
  "2026-08-23": "9-dots",
  "2026-08-30": "treasure-hunt",
  "2026-09-06": "russian-multiplication",
  "2026-09-13": "river-crossing",
  "2026-09-20": "poisoned-wine-puzzle",
  "2026-09-27": "matchstick-puzzles",
  "2026-10-04": "magic-cards",
  "2026-10-11": "langfords-problem",
  "2026-10-18": "handshake-puzzles",
  "2026-10-25": "geometry-puzzle-1",
  "2026-11-01": "gabriels-problem",
  "2026-11-08": "fence-around-a-field",
  "2026-11-15": "domino-tilings",
  "2026-11-22": "collatz-conjecture",
  "2026-11-29": "coin-game",
  "2026-12-06": "coconut-trader",
  "2026-12-13": "circumference",
  "2026-12-20": "apple-teaser",
  "2026-12-27": "two-eggs",
  "2027-01-03": "special-numbers",
  "2027-01-10": "scales-and-weights",
  "2027-01-17": "picture-puzzles-1",
  "2027-01-24": "monkey-business",
  "2027-01-31": "locks-and-keys",
  "2027-02-07": "frog-party",
  "2027-02-14": "counting-chickens",
  "2027-02-21": "tic-tac-toe",
  "2027-02-28": "tic-tac-toe-with-levels",
  "2027-03-07": "tangrams",
  "2027-03-14": "secret-santa",
  "2027-03-21": "patience",
  "2027-03-28": "paper-pieces",
  "2027-04-04": "nim-related-games",
  "2027-04-11": "nim-21",
  "2027-04-18": "mastermind",
  "2027-04-25": "make-many",
  "2027-05-02": "letter-matching",
  "2027-05-09": "countdown",
  "2027-05-16": "15-game",
  "2027-05-23": "tetris-shapes",
  "2027-05-30": "konigsberg-bridges",
  "2027-06-06": "kaprekars-number",
  "2027-06-13": "diffy-squares",
  "2027-06-20": "where-on-earth",
  "2027-06-27": "sdt-riddles",
  "2027-07-04": "minimax",
  "2027-07-11": "colouring-maps",
  "2027-07-18": "bulgarian-solitaire",
  "2027-07-25": "pell-numbers",
  "2027-08-01": "paths",
  "2027-08-08": "number-challenge-1",
  "2027-08-15": "making-squares",
  "2027-08-22": "logic-puzzles-1",
  "2027-08-29": "iq-challenge-1",
  "2027-09-05": "find-the-path",
  "2027-09-12": "counting-squares",
  "2027-09-19": "buffons-needle",
  "2027-09-26": "balls-and-books",
  "2027-10-03": "addition-squares",
  "2027-10-10": "adding-to-15",
  "2027-10-17": "9-dots",
  "2027-10-24": "treasure-hunt",
  "2027-10-31": "russian-multiplication",
  "2027-11-07": "river-crossing",
  "2027-11-14": "poisoned-wine-puzzle",
  "2027-11-21": "matchstick-puzzles",
  "2027-11-28": "magic-cards",
  "2027-12-05": "langfords-problem",
  "2027-12-12": "handshake-puzzles",
  "2027-12-19": "geometry-puzzle-1",
  "2027-12-26": "gabriels-problem",
  "2028-01-02": "fence-around-a-field",
  "2028-01-09": "domino-tilings",
  "2028-01-16": "collatz-conjecture",
  "2028-01-23": "coin-game",
  "2028-01-30": "coconut-trader"
}