import { Component } from "@angular/core";

@Component({
  selector: "app-app-terms",
  templateUrl: "./app-terms.component.html",
  styleUrls: ["./app-terms.component.scss"],
  standalone: true,
  imports: [],
})
export class AppTermsComponent {}
