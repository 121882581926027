<mat-sidenav-container>
  <mat-sidenav #snav mode="over">
    <mat-nav-list
      (click)="snav.close()"
      class="safe-padding-top"
      style="margin-top: 1rem"
    >
      <mat-list-item routerLink="/">
        <mat-icon matListItemIcon>home</mat-icon>
        <a matListItemTitle href="/"> Problems</a>
      </mat-list-item>

      <div class="bottom-items safe-padding-side safe-padding-bottom">
        <a mat-list-item routerLink="privacy">Privacy Policy</a>
        <a mat-list-item routerLink="app-terms">Terms and Conditions</a>
        <a mat-list-item routerLink="contact">Contact</a>
        <div id="version">v{{ version }}</div>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- Additional div to cover notch area on some iphones -->
    <div class="notch-cover mat-toolbar mat-primary safe-padding-top">
      <mat-toolbar color="primary">
        @if (!params().slug) {
        <button mat-icon-button (click)="snav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        } @if (params().slug) {
        <button mat-icon-button (click)="appService.goBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        }
        <span class="nav-title">SAMI Maths Club</span>
        <app-language-switcher
          style="margin-right: 1em"
        ></app-language-switcher>
      </mat-toolbar>
    </div>
    <main
      [@slideTransition]="getRouteAnimationState(outlet)"
      class="content safe-padding-side safe-padding-bottom"
    >
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
